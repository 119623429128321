import { createI18n } from 'vue-i18n'
import en from './en'
import et from './et'
import ru from './ru'

const messages = {
  et,
  en,
  ru
};

const i18n = createI18n({
  locale: 'et', 
  fallbackLocale: 'en',
  messages: messages,
});

export default i18n;