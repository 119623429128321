<template>
    <header>
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
          <a class="navbar-brand" href="/">
            <img src="@/assets/images/logo_white.svg" alt="">
          </a>
          <div class="">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 list-group-horizontal">
              <li class="nav-item">
                <a class="nav-link" :class="{ active: locale === 'et'}" aria-current="page" href="#" @click="setLocale('et')">EE</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{ active: locale === 'en'}" href="#" @click="setLocale('en')">EN</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{ active: locale === 'ru'}" href="#" @click="setLocale('ru')">RU</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
</template>
<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import i18n from '@/translations/i18n'
const store = useStore()
const locale = ref(localStorage.getItem('locale') ? localStorage.getItem('locale') : 'et' )
i18n.global.locale = locale.value
store.commit('setLocale', locale.value)
document.title = i18n.global.t('Kuulsaal - Reservation')
function setLocale (lc) {
  i18n.global.locale = lc
  locale.value = lc
  store.commit('setLocale', lc)
  localStorage.setItem("locale", lc)
  document.title = i18n.global.t('Kuulsaal - Reservation')
}
</script>