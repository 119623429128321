import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import '@/assets/scss/main.css'
import "bootstrap"
import i18n from "@/translations/i18n";

createApp(App)
  .use(store)
  .use(i18n)
  .use(router)
  .mount('#app')
