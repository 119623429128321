<template>
  <div class="container">
    <div class="row">
      <div id="booking" class="col-xl-5 col-lg-7 col-md-8 mx-auto">
        <h1>{{ $t('Booking') }}</h1>
        <div class="steps">
          <div class="step-complete one-fifth"></div>
          <div class="step-data">
            <p>1/5</p>
          </div>
          <div class="step-incomplete one-fifth"></div>
        </div>
        <form class="details needs-validation" action="" novalidate>
          <h2>{{ $t('Location') }}</h2>
           <div class="vld-parent" v-if="isLoading">
              <img src="../assets/images/spinner-loop.gif" height="200"/>
            </div>
          <template v-else>
            <div class="row box-radios location w-100 mx-auto">
              <template v-for="(alocation, index) in availableLocations" :key="index">
                <div class="form-check custom-check col-md-6"  @click="setLocation(alocation.id)">
                  <input class="form-check-input" type="radio" :checked="alocation.id === location">
                  <label class="form-check-label" for="inlineRadio1">{{alocation.name}}</label>
                </div>
              </template>
              <!-- <div class="form-check custom-check col-md-6" @click="setLocation('Viimsi')">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Viimsi" required>
                <label class="form-check-label" for="inlineRadio2">Viimsi</label>
              </div> -->
            </div>
          </template>
          <div class="row">
            <div class="col-md-6 mx-auto text-center">
              <router-link to="/step2" @click="setIsLoading(true)">
                <a type="submit" href="#" class="btn btn-primary">{{ $t('Select location') }}</a>
              </router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'

export default {
  name: 'Step1',
  
  mounted () {
    this.getAvailableLocations();
    this.setIsLoading(true);

    if(this.$route.query.discount_code) {
      this.setDiscountCode(this.$route.query.discount_code);
    }
  },

  computed: {
    ...mapState({
      location: state => state.location,
      availableLocations: state => state.available_locations,
      isLoading: state => state.is_loading,
      locale: state => state.locale,
    })
  },

  watch: {
    availableLocations (value) {
      if (value.length > 0){
        setTimeout(() => {
          this.setIsLoading(false)
        }, 1000)
      }
    },
    locale (oldVal, newVal) {
      if (oldVal !== newVal) {
        this.getAvailableLocations();
        this.setIsLoading(true);
      }
    }
  },

  methods: {
    ...mapMutations([
      'setLocation',
      'setIsLoading',
      'setDiscountCode',
    ]),

    ...mapActions([
      'getAvailableLocations'
    ])
  }
}
</script>
<style scoped>
  .vld-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom:50px;
  }
</style>

