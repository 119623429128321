<template>
  <div class="container">
    <div class="row">
      <div id="booking" class="col-xl-5 col-lg-7 col-md-8 mx-auto">
        <div class="row">
          <div class="col-md-12 success">
            <h2>{{ $t('Are you sure you wish to cancel your booking?') }}</h2>
            <p class="lead">{{ $t('Order number:') }} {{ $route.params.order_number }}</p>
            <div class="row gy-2">
              <div class="col-md-6">
                <a type="submit" href="https://kuulsaal.ee" class="btn btn-secondary">{{ $t('No, do not cancel') }}</a>
              </div>

              <div class="col-md-6">
                <a type="submit" :href="cancel_url" @click="redirectToCancel()" class="btn btn-primary">{{ $t('Yes, cancel booking') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      cancel_url: process.env.VUE_APP_API_URL + '/api/order/' + this.$route.params.order_number + '/cancel',
    }
  },
}
</script>

<style scoped>
.success-image {
  width: 150px;
}
</style>
