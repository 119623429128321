<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto content">
          <p v-html="$t('By booking the time you agree that you have read and got acquainted with us')"></p>

          <p v-html="$t('If you wish to pay under the warranty, please contact us to make a reservation.')"></p>
        </div>
      </div>
    </div>
  </footer>
</template>