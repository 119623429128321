<template>
  <div class="container">
    <div class="row">
      <div id="booking" class="col-xl-5 col-lg-7 col-md-8 mx-auto">
        <h1>{{ $t('Booking') }}</h1>
        <div class="steps">
          <div class="step-complete five-fifth"></div>
          <div class="step-data">
            <p>5/5</p>
          </div>
          <div class="step-incomplete five-fifth"></div>
        </div>
        <div class="row" v-if="redirect_url">
          <div class="col-md-12 success">
            <div class="vld-parent">
              <img src="@/assets/images/spinner-loop.gif" height="200"/>
            </div>
            <p>{{ $t('Redirecting to payment page, please wait') }}</p>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-md-12 success">
            <img class="mx-auto success-image" src="@/assets/images/success-icon.png" alt="">
            <h2>{{ $t('Thank you for your booking!') }}</h2>
            <p>{{ $t('A booking confirmation has been sent to your e-mail address.') }}</p>
          </div>
        </div>
        <div class="row" v-if="!redirect_url">
          <div class="col-md-6 mx-auto text-center">
            <router-link to="/">
              <a href="#" class="btn btn-primary">{{ $t('Back to homepage') }}</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default{
  mounted () {
    // eslint-disable-next-line no-undef
    mt("send", "pageview", { email: this.email, started_booking: false });

    if (this.redirect_url ) {
      setTimeout(()=>{
        window.location.href = this.redirect_url
      },3000)
    }

    if (this.errors.length > 0) {
      this.$router.push('/payment-error')
    }
  },
  computed: {
    ...mapState({
      redirect_url: state =>state.redirect_url,
      errors: state => state.errors
    })
  }
}
</script>
<style scoped>
.success-image {
  width: 150px;
}
</style>
