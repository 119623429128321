<template>
  <div class="container">
    <div class="row">
      <div id="booking" class="col-xl-5 col-lg-7 col-md-8 mx-auto">
        <div class="row">
          <div class="col-md-12 success">
            <img class="mx-auto error-image" src="@/assets/images/error-icon.png" alt="">
            <h2>{{ $t('There was a problem with the payment!') }}</h2>
            <p v-html="$t('Your payment could not be processed. If you think this was a mistake, please contact our customer support.')"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.error-image {
  width: 150px;
}
</style>
