<template>
  <div class="container">
    <div class="row">
      <div id="booking" class="col-xl-5 col-lg-7 col-md-8 mx-auto">
        <h1>{{ $t('Booking') }}</h1>
        <div class="steps">
          <div class="step-complete three-fifth"></div>
          <div class="step-data">
            <p>3/5</p>
          </div>
          <div class="step-incomplete three-fifth"></div>
        </div>
        <form action="">
          <h2>{{ $t('Date') }}</h2>
          <div class="input-group date">
            <datepicker v-model="bookDateVal" :lowerLimit="new Date()" :upperLimit="twoYearsAhead()" inputFormat="dd.MM.yyyy" class="form-control" style="cursor:pointer" placeholder="Vali kuupäev" aria-describedby="basic-addon1"/>
            <span class="form-control-icon">
              <img src="../assets/images/calendar.svg" alt="Vali kuupäev">
            </span> 
          </div>
          <h2>{{ $t('Time') }}</h2>
          <p>{{ $t('Select a date to see availability!') }}</p>
            <div class="vld-parent" v-if="isLoading">
              <img src="../assets/images/spinner-loop.gif" height="200"/>
            </div>
            <template v-else>
              <div class="row box-radios time w-100 mx-auto" id="time-select" v-if="availableTimes.length > 0">
                <div class="form-check custom-check col-md-6" v-for="(atime, index) in availableTimes" :key="index" @click="setTime(atime)">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" :value="atime" :checked="atime === bookTime" required>
                  <label class="form-check-label" for="time-12">{{ atime }}</label>
                </div>
              </div>
              <h1 v-else>
                {{ $t('There are no times available on this day.') }}
              </h1>
            </template>
          

          <div class="row pagination">
            <div class="col-6">
              <a href="javascript:void(0)" class="prev disabled" @click="previousDate()">{{ $t('Previous day') }}</a>
            </div>
            <div class="col-6">
              <a href="javascript:void(0)" class="next active" @click="nextDate()"><span>{{ $t('Next day') }}</span></a>
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-center">
              <router-link to="/step2">
                <a href="javascript: void(0)" class="btn btn-secondary">{{ $t('Go back') }}</a>
              </router-link>
            </div>
            <div class="col-6 text-center">
              <a href="#time-select" class="btn btn-primary" :class="{disabled: bookTime ? false : true }" @click="checkInput()">{{ $t('Select a time') }}</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import Datepicker from 'vue3-datepicker'
  import moment from 'moment'
  import { mapMutations, mapActions, mapState } from 'vuex'

  export default{
    mounted () {
      if(this.bookDate){
        this.bookDateVal =  moment(this.bookDate, 'DD.MM.YYYY').toDate();
      }

      if(!this.checkRequiedInput()){
        this.$router.push('step2')
      }
      
      this.getAvailableTimes()

      this.setTime('')
    },

    components: {
      Datepicker
    }, 

    data () {
      return {
        bookDateVal: moment().toDate(this.bookDate),
        fullPage: false
      }
    },

    computed: {
      ...mapState({
        isLoading: state => state.is_loading,
        availableTimes: state => state.available_times,
        bookTime: state => state.book_time,
        service: state => state.service,
        bookDate: state => state.book_date
      })
    },

    watch: {
      bookDateVal(newDate, oldDate){
        if(oldDate != newDate){
          this.setBookDate(moment(newDate).format('DD.MM.YYYY'))
          this.setIsLoading(true)
          this.getAvailableTimes()
        }
      },

      availableTimes(data){
        let vm = this
        if(data){
          setTimeout(function(){
            vm.setIsLoading(false)
          }, 2000)
        }
      }
    },

    methods: {
      ...mapMutations([
        'setBookDate',
        'setTime',
        'setIsLoading',
        'setRouteName'
      ]),

      ...mapActions([
        'getAvailableTimes'
      ]),

      previousDate(){
        let bookDateVal = moment(this.bookDateVal)
        let bookDateMinusOne = bookDateVal.subtract(1, 'days');
        let dateToday = moment(new Date())
        if( !bookDateMinusOne.isBefore(dateToday, 'day') ){
          this.bookDateVal = bookDateMinusOne.toDate()
          this.setBookDate(bookDateMinusOne.format('DD.MM.YYYY'))
        }
      },

      nextDate(){
        let bookDateVal = moment(this.bookDateVal)
        let bookDatePlusOne = bookDateVal.add(1, 'days');
        this.bookDateVal = bookDatePlusOne.toDate()
        this.setBookDate(bookDatePlusOne.format('DD.MM.YYYY'))
      },
      checkInput () {
        if(this.bookTime){
          this.setIsLoading(true)
          this.$router.push('step4')
        } 
      },
      checkRequiedInput () {
        return this.service ? true : false
      },
      setBookDatePrep (value) {
        console.log(value)
        this.setBookDate(moment(value).format('DD.MM.YYYY'))
      },
      twoYearsAhead () {
        const dateToday = new Date();
        dateToday.setFullYear(dateToday.getFullYear() + 2);
        return dateToday
      }
    }
  }
</script>
<style scoped>
  .v3dp__datepicker {
    width: 100%;
  }
  .v3dp__popout {
    z-index: 99999999;
  }
  .vld-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom:50px;
  }
</style>
