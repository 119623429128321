<template>
  <div class="container">
    <div class="row">
      <div id="booking" class="col-xl-5 col-lg-7 col-md-8 mx-auto">
        <div class="row">
          <div class="col-md-12 success">
            <img class="mx-auto success-image" src="@/assets/images/success-icon.png" alt="">
            <h2>{{ $t('The booking has been cancelled.') }}</h2>
            <p>{{ $t('Your booking has been cancelled. If you have made an advance payment and there is more than 72 hours until your booking, the refund will be made to your bank account as soon as possible.') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.success-image {
  width: 150px;
}
</style>
